<template>
  <div class="flex p-2 space-x-2">
    <button
      v-for="filter in filters"
      :key="filter.id"
      :class="[
        'px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ease-in-out',
        activeFilter === filter.id
          ? 'bg-black text-white'
          : 'bg-white text-gray-700 hover:bg-gray-200'
      ]"
      @click="setActiveFilter(filter.id)"
    >
      {{ filter.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FilterComponent",
  data() {
    return {
      activeFilter: "all",
      filters: [
        { id: "all", label: "All" },
        { id: "action-needed", label: "Action Needed" },
        { id: "waiting", label: "Waiting" },
        { id: "protected", label: "Protected" }
      ]
    };
  },
  methods: {
    setActiveFilter(filterId) {
      this.activeFilter = filterId;
      this.$emit("filter-changed", filterId);
    }
  }
};
</script>
